import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import settings from '/settings';

function Head({ lang, title, description, meta, link }) {
	const { metadata } = settings;
	const defaultTitle = metadata.title;
	const metaDescription = description || metadata.description;
	const metaTitle = title ? `${title} | ${defaultTitle}` : defaultTitle;

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={metaTitle}
			meta={[
				{ property: 'description', content: metaDescription },
				{ property: 'og:title', content: metaTitle },
				{ property: 'og:site_name', content: metadata.siteName },
				{ property: 'og:description', content: metaDescription },
				{ property: 'og:type', content: 'website' },
				{ property: 'og:url', content: metadata.url },
				{ property: 'og:image', content: metadata.image },
			].concat(meta)}
			link={link}
		/>
	);
}

Head.defaultProps = {
	lang: 'en',
	title: '',
	description: '',
	meta: [],
	link: [],
};

Head.propTypes = {
	lang: PropTypes.string,
	title: PropTypes.string,
	description: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	link: PropTypes.arrayOf(PropTypes.object),
};

export { Head };
