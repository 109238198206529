import React from 'react';
import { Head } from './head';

import { ArwesThemeProvider } from '@arwes/core';
import { BleepsProvider } from '@arwes/sounds';
import soundConfig from 'src/config/sound.json';
import { AnimatorGeneralProvider } from '@arwes/animation';
import animatorConfig from 'src/config/animation.json';

const WrapRootElement = ({ element }) => {
	return (
		<>
			<Head></Head>
			<ArwesThemeProvider
				themeSettings={{
					typography: {
						content: '"Titillium Web", sans-serif',
						monospace: '"Source Code Pro", monospace',
					},
				}}
			>
				<BleepsProvider
					audioSettings={soundConfig.common}
					playersSettings={soundConfig.players}
					bleepsSettings={soundConfig.bleeps}
				>
					<AnimatorGeneralProvider animator={animatorConfig}>{element}</AnimatorGeneralProvider>
				</BleepsProvider>
			</ArwesThemeProvider>
		</>
	);
};

export { WrapRootElement };
