let baseURL = process.env.GATSBY_BASE_DOMAIN

module.exports = {
    base: baseURL,
    photos: "https://photos." + baseURL,
    inuPhotos: "https://photos." + baseURL + "/Inu-My-Kitten-e78449de648c49748352da7ee4bc8c61",
    guinness: "https://" + baseURL + "/files/guinness.pdf",
    rackPhotos: "https://" + baseURL + "/files/rack-in-closet.jpg",
    myImage: "https://" + baseURL + "/images/me/zxwang1.jpg"
}
