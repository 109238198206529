import { WrapRootElement } from './src/components/WrapRootElement';

// import "./src/css/normalize.css"
// import "./src/css/style-animations.css"
// import "./src/css/global.css"
// import "./src/css/splash.css"
import "@fontsource/titillium-web"
import "@fontsource/titillium-web/300.css"
import "@fontsource/titillium-web/600.css"

const wrapRootElement = WrapRootElement;

export { wrapRootElement };
