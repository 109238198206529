const domain = require('./src/config/domain');

module.exports = {
	metadata: {
		title: 'Zixuan Wang | PhD, UC San Diego',
		siteName: 'Zixuan Wang',
		description: 'PhD from UC San Diego, Software Engineer at Apple',
		author: '@TheNetAdmin',
		url: 'https://' + domain.base + '',
		image: domain.myImage,
		twitter: '@TheNetAdmin1',
		canonical: 'https://zixuan.wang'
	},
	gaTrackingId: 'UA-161712260-3',
};
